function mainReviewsInit() {
  const swiper = new Swiper('.main-reviews__slider', {
    navigation: {
      nextEl: '.main-reviews__slider .swiper-navigation__btn--next',
      prevEl: '.main-reviews__slider .swiper-navigation__btn--prev',
    },

    on: {
      init: function () {
        let swiperNavigationCounterAll = document.querySelector('.swiper-navigation__counter-all');
        let swiperNavigationCounterCurrent = document.querySelector('.swiper-navigation__counter-current');

        swiperNavigationCounterAll.textContent = this.slides.length < 10 ? "0" + this.slides.length : this.slides.length
        swiperNavigationCounterCurrent.textContent = this.activeIndex + 1 < 10 ? "0" + (this.activeIndex + 1) : this.activeIndex + 1
      },
      slideChange: function () {
        let swiperNavigationCounterCurrent = document.querySelector('.swiper-navigation__counter-current');
        swiperNavigationCounterCurrent.textContent = this.activeIndex + 1 < 10 ? "0" + (this.activeIndex + 1) : this.activeIndex + 1
      },
    },
  });
}

export { mainReviewsInit }