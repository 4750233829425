function bannerInit() {
  const swiper = new Swiper('.banner__slider', {
    loop: true,
  
    pagination: {
      el: '.banner__slider .swiper-pagination',
    },
  });
}

export { bannerInit }