function headerInit() {
  let headerHamburger = document.querySelector('.header__hamburger');
  let headerMenu = document.querySelector('.header__menu');

  if (headerHamburger) {
    headerHamburger.addEventListener('click', () => {
      headerHamburger.classList.toggle('is-active');
      headerMenu.classList.toggle('is-active');
    })
  }
}

export { headerInit }