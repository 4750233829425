function lineInit() {
  const swiper = new Swiper('.line__slider', {
    spaceBetween: 40,
    slidesPerView: 3,

    navigation: {
      nextEl: '.line__slider .swiper-navigation__btn--next',
      prevEl: '.line__slider .swiper-navigation__btn--prev',
    },

    breakpoints: {
      // when window width is >= 0px
      0: {
        slidesPerView: 1,
      },
      // when window width is >= 1024px
      1024: {
        slidesPerView: 3,
      },
    },
  });
}

export { lineInit }