function cardInit() {
  var swiper = new Swiper(".card__preview-slider", {
    spaceBetween: 10,
    slidesPerView: 3,

    breakpoints: {
      // when window width is >= 0px
      0: {
        slidesPerView: 1,
      },
      // when window width is >= 1024px
      1024: {
        slidesPerView: 3,
      },
    },
  });
  var swiper2 = new Swiper(".card__main-slider", {
    spaceBetween: 10,

    thumbs: {
      swiper: swiper,
    },
  });
}

export { cardInit }