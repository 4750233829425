function mainCatalogInit() {
  const swiper = new Swiper('.main-catalog__card-slider', {
    loop: true,
  
    pagination: {
      el: '.main-catalog__card-slider .swiper-pagination',
    },
  });
}

export { mainCatalogInit }