function footerInit() {
  let footerGoToTop = document.querySelector('.footer__go-to-top');

  if (footerGoToTop) {
    footerGoToTop.addEventListener('click', () => {
      window.scrollTo(0, 0)
    })
  }
}

export { footerInit }